import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  Typography,
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { colors } from '../../theme'
import FlashOnIcon from '@material-ui/icons/FlashOn';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BarChartIcon from '@material-ui/icons/BarChart';
import PieChartIcon from '@material-ui/icons/PieChart';
import SecurityIcon from '@material-ui/icons/Security';
import ChatIcon from '@material-ui/icons/Chat';

import SendIcon from '@material-ui/icons/Send';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import GrainIcon from '@material-ui/icons/Grain';
import LanguageIcon from '@material-ui/icons/Language';
import LockIcon from '@material-ui/icons/Lock';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import TerrainIcon from '@material-ui/icons/Terrain';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';


const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    }
  },
  card: {
    flex: '1',
    height: '25vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    borderRadius: '0px',
    transition: 'background-color 0.2s linear',
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
      minHeight: '50vh',
    }
  },
  swap: {
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.tomato,
      '& .title': {
        color: colors.white
      },
      '& .icon': {
        color: colors.white
      },
      '& .description': {
        display: 'block',
        color: colors.white,
        padding: '48px',
        textAlign: 'center'
      }
    },
    '& .title': {
      color: colors.tomato
    },
    '& .icon': {
      color: colors.tomato
    },
    '& .description': {
      display: 'none'
    }
  },
  ethBlue: {
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.blue,
      '& .title': {
        color: colors.white,
      },
      '& .icon': {
        color: colors.white
      },
      '& .description': {
        display: 'block',
        color: colors.white,
        padding: '48px',
        textAlign: 'center'
      }
    },
    '& .title': {
      color: colors.blue,
      display: 'block'
    },
    '& .soon': {
      color: colors.blue,
      display: 'none'
    },
    '& .icon': {
      color: colors.blue
    },
    '& .description': {
      display: 'none'
    }
  },
  telegram: {
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.blue,
      '& .title': {
        color: colors.white,
      },
      '& .icon': {
        color: colors.white
      },
      '& .description': {
        display: 'block',
        color: colors.white,
        padding: '48px',
        textAlign: 'center'
      }
    },
    '& .title': {
      color: colors.blue,
      display: 'block'
    },
    '& .soon': {
      color: colors.blue,
      display: 'none'
    },
    '& .icon': {
      color: colors.blue
    },
    '& .description': {
      display: 'none'
    }
  },
  byz: {
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.purple,
      '& .title': {
        color: colors.white,
      },
      '& .icon': {
        color: colors.white
      },
      '& .description': {
        display: 'block',
        color: colors.white,
        padding: '48px',
        textAlign: 'center'
      }
    },
    '& .title': {
      color: colors.purple,
    },
    '& .icon': {
      color: colors.purple
    },
    '& .description': {
      display: 'none'
    }
  },
  title: {
    padding: '24px',
    paddingBottom: '0px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '24px'
    }
  },
  icon: {
    fontSize: '60px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '100px',
    }
  },
  link: {
    textDecoration: 'none'
  },
  centered: {
    textAlign: 'center'
  }
});

class Home extends Component {

  constructor(props) {
    super()

    this.state = {
    }
  }

  render() {
    const { classes, t, location } = this.props;

    return (
      <div className={ classes.root }>
        <Card className={ `${classes.card} ${classes.ethBlue}` } onClick={ () => { window.open('https://uniswap.info/token/0x539efe69bcdd21a83efd9122571a64cc25e0282b') } }>
          <VerifiedUserIcon  className={ `${classes.icon} icon` } />
          <Typography variant={'h3'} className={ `${classes.title} title ${classes.centered}` }>Ethereum<br />BLUE</Typography>
          <Typography variant={'h4'} className={ `${classes.description} description` }>{ "Secure your Ethereum portfolio and Smart Contracts with BLUE." }</Typography>
        </Card>
        <Card className={ `${classes.card} ${classes.byz}` } onClick={ () => { window.open('https://www.byz.network/') } }>
          <SelectAllIcon className={ `${classes.icon} icon` } />
          <Typography variant={'h3'} className={ `${classes.title} title ${classes.centered}` }>Bitcoin<br />BYZ</Typography>
          <Typography variant={'h4'} className={ `${classes.description} description` }>{ "Secure your Bitcoin portfolio with BYZ." }</Typography>
        </Card>
        <Card className={ `${classes.card} ${classes.swap}` } onClick={ () => { this.nav(location.pathname+'') } }>
          <FlashOnIcon className={ `${classes.icon} icon` } />
          <Typography variant={'h3'} className={ `${classes.title} title ${classes.centered}` }>Instant Swap<br /><small>(coming soon)</small></Typography>
          <Typography variant={'h4'} className={ `${classes.description} description` }>{ "Securely swap between BLUE, BYZ, ETH, BTC, and other products." }</Typography>
        </Card>
        <Card className={ `${classes.card} ${classes.telegram}` } onClick={ () => { window.open('https://t.me/BlueProtocolBYZ') } }>
          <SendIcon className={ `${classes.icon} icon` } />
          <Typography variant={'h3'} className={ `${classes.title} title ${classes.centered}` }>Telegram</Typography>
          <Typography variant={'h4'} className={ `${classes.description} description` }>{ "Join the community." }</Typography>
        </Card>
        {/* <Card className={ `${classes.card} ${classes.apr}` } onClick={ () => { this.nav(location.pathname+'dashboard') } }>
          <BarChartIcon className={ `${classes.icon} icon` } />
          <Typography variant={'h3'} className={ `${classes.title} title` }>Dashboard</Typography>
          <Typography variant={'h4'} className={ `${classes.description} description` }>{ "Get a quick glance at how your portfolio is growing while invested in yearn's products." }</Typography>
        </Card>
        <Card className={ `${classes.card} ${classes.vault}` } onClick={ () => { this.nav(location.pathname+'vaults') }}>
          <PieChartIcon className={ `${classes.icon} icon` } />
          <Typography variant={'h3'} className={ `${classes.title} title` }>{ t("Home.Vaults") }</Typography>
          <Typography variant={'h4'} className={ `${classes.description} description` }>{ "Vaults follow unique strategies that are designed to maximize the yield of the deposited asset and minimize risk." }</Typography>
        </Card>
        <Card className={ `${classes.card} ${classes.earn}` } onClick={ () => { this.nav(location.pathname+'earn') } }>
          <AttachMoneyIcon className={ `${classes.icon} icon` } />
          <Typography variant={'h3'} className={ `${classes.title} title` }>{ t("Home.Earn") }</Typography>
          <Typography variant={'h4'} className={ `${classes.description} description` }>{ "Earn performs profit switching for lending providers, moving your funds between dydx, Aave, Compound autonomously." }</Typography>
        </Card>
        <Card className={ `${classes.card} ${classes.zap}` } onClick={ () => { this.nav(location.pathname+'zap') } }>
          <FlashOnIcon className={ `${classes.icon} icon` } />
          <Typography variant={'h3'} className={ `${classes.title} title` }>{ t("Home.Zap") }</Typography>
          <Typography variant={'h4'} className={ `${classes.description} description` }>{ "Zaps help you save on gas fees. Zap directly into or out of Curve pools from the base assets." }</Typography>
        </Card>
        <Card className={ `${classes.card} ${classes.cover}` } onClick={ () => { window.open("https://yinsure.finance", "_blank") } }>
          <SecurityIcon className={ `${classes.icon} icon` } />
          <Typography variant={'h3'} className={ `${classes.title} title` }>{ t("Home.Cover") }</Typography>
          <Typography variant={'h4'} className={ `${classes.description} description` }>{ "Get cover with Nexus Mutual from yinsure.finance" }</Typography>
        </Card>
        <Card className={ `${classes.card} ${classes.apr}` } onClick={ () => { this.nav(location.pathname+'stats') } }>
          <BarChartIcon className={ `${classes.icon} icon` } />
          <Typography variant={'h3'} className={ `${classes.title} title` }>Stats</Typography>
          <Typography variant={'h4'} className={ `${classes.description} description` }>{ "Get a quick glance at how yearn's vaults are performing." }</Typography>
        </Card> */}
      </div>
    )
  };

  nav = (screen) => {
    this.props.history.push(screen)
  }
}

export default withNamespaces()(withRouter(withStyles(styles)(Home)));
